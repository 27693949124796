<template>
  <div>
    <h3>INFORMACION A USUARIOS FINANCIEROS</h3>
    <h4 class="textAlignLeft">Canales de contacto:</h4>
    <p class="textAlignLeft">
      WSP: <a href="https://wa.me/541163504807">11-6350-4807</a>
    </p>
    <p class="textAlignLeft">Mails:</p>
    <p class="textAlignLeft">
      <a href="mailto:consultas@prester.com.ar">consultas@prester.com.ar</a>
    </p>
    <p class="textAlignLeft">
      <a href="mailto:reclamos@prester.com.ar">reclamos@prester.com.ar</a>
    </p>
    <p class="textAlignLeft">Responsables de Atención al Usuario Financiero</p>
    <p class="textAlignLeft">Titular: Cecilia Senilliani</p>
    <p class="textAlignLeft">Suplente: Gabriela Tuset</p>
    <p class="textAlignLeft">
      Mail de contacto:
      <a href="mailto:consultas@prester.com.ar">consultas@prester.com.ar</a>
    </p>
    <p class="textAlignLeft">
      Domicilio laboral: 25 de Mayo 306, piso 2, CP 1002, CABA
    </p>
    <p class="textAlignLeft">
      Podés ponerte en contacto con nosotros para que te brindemos ayuda
      personalizada por nuestros canales de atención.
    </p>
    <p class="textAlignLeft">
      En caso de no estar de acuerdo con nuestra respuesta, podes comunicarte
      con la Gerencia Principal de Protección a Usuarios Financieros a través de
      su sitio: www.usuariosfinancieros.gob.ar
    </p>
  </div>
</template>

<script>
export default {
  name: "InformationToFinancialUsers",
  methods: {},
};
</script>


<style scoped>
.textAlignLeft {
  text-align: left;
  margin-left: 100px;
}
</style>
